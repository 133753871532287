/*import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage*/

import React, { Component } from 'react'
import { Box } from '@material-ui/core'
import { Fab } from 'gatsby-theme-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Ticker from '../components/ticker'
import Footer from '../components/footer'
import logo from '../images/logo.png'
import tsLogo from '../images/tsLogo.png'
import './index.css'

class Home extends Component {
    render(){
        const { classes } = this.props
        return (
            <Box className={classes.container}>
              <Box className={classes.body}>
                <Box width="95%" paddingTop="10px" height="27vh">
                    <Box width="15%"><img src={logo} alt="SparkDJ_Logo" /></Box>
                </Box>
                <Box className={classes.headline}>Gamify your background music.</Box>
                <Box><h3 className={classes.caption}>Have fun and capture customer data with the music game that lets the<br />crowd pick the music without ruining your playlist.</h3></Box>
                <Box className={classes.button}><Fab style={{maxWidth:'90%', width:'200px'}} variant="extended" href="mailto:contact@spark.dj">Contact Us</Fab></Box>
                <Box className={classes.logoContainer}>
                    <Box className={classes.ticker}><Ticker/></Box>
                    <Box className={classes.tsLogoContainer}>
                        <Box className={classes.tsLogo}><img style={{objectFit: 'contain'}} src={tsLogo} alt="Techstars_Logo" /></Box>
                    </Box>
                </Box>
              </Box>
              <Box className={classes.footer}>
                  <Footer />
              </Box>
            </Box>
        )
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        height: '100vh',
        width: '100%',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems: 'center',
        background: 'radial-gradient(#05163C,#000000)',
        color: 'white',
    },
    body: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    headline: {
        height: '100%',
        maxHeight: '14vh',
        textAlign: 'center',
        fontSize: '4.3rem',
        fontFamily: theme.typography.h6.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    caption: {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightLight,
    },
    button: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'

    },
    logoContainer: {
        display: 'flex',
        paddingTop: '20px',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    ticker: {
        height: '12vh',
        opacity: '50%'
    },
    tsLogoContainer: {
        display: 'flex',
        paddingTop: '10px',
        width:'100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tsLogo: {
        display: 'flex',
        alignItems: 'center',   
        width: '90%',
        maxWidth: '200px',     
    }, 
    footer: {
        width: '100%',
    }
})

export default withStyles(styles)(Home)