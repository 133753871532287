import React from 'react'
import Ticker from 'react-ticker'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import amazonLogo from '../images/tickerImages/amazonLogo.png'
import caesarsLogo from '../images/tickerImages/caesarsLogo.png'
import capitalFactoryLogo from '../images/tickerImages/capitalFactoryLogo.png'
import firstaveLogo from '../images/tickerImages/firstaveLogo.png'
import sxswLogo from '../images/tickerImages/sxswLogo.png'
import targetLogo from '../images/tickerImages/targetLogo.png'
import usbankLogo from '../images/tickerImages/usbankLogo.png'
import billboardLogo from '../images/tickerImages/billboardLogo.png'
import forbesLogo from '../images/tickerImages/forbesLogo.png'
import '../pages/index.css'

const images = [
	amazonLogo,
	caesarsLogo,
	billboardLogo,
	capitalFactoryLogo,
	firstaveLogo,
	sxswLogo,
	forbesLogo,
	targetLogo,
	usbankLogo,
]

class CustomerTicker extends React.Component {
	render(){
		return (		
		    <Ticker speed={3}>
		        {() => (
		        	<Box width='1650px' style={{whiteSpace: 'nowrap'}}>
	        			{
	        				images.map((imageSrc) => (
	        					<img height="80px"  style={{maxWidth:'200px', paddingRight:"15px",paddingLeft:"15px"}} src={imageSrc} alt=""/>
	        				))
	        			}
		        		
		        	</Box>	
		        )}
		    </Ticker>
		)
	}
}

const styles = theme => ({

})

export default withStyles(styles)(CustomerTicker)